import {
    Card,
    MarketingPageLayout,
    Box,
    MarketingPageLayoutContent,
    Text,
    WixDesignSystemProvider,
    Image,
    Heading,
    Button,
    Modal,
    Page,
    EmptyState,
    CircularProgressBar,
    Cell,
    AnnouncementModalLayout,
    Badge,
    Loader,
    MarketingLayout,
    Layout,
    CustomModalLayout,
    FormField,
    Input,
    FloatingNotification,
    CopyClipboard,
    TextButton,
    Notification,
} from '@wix/design-system';
import '@wix/design-system/styles.global.css';
import * as Icons from '@wix/wix-ui-icons-common';
import React from 'react';
import LandingImage from './assets/lib-app-landing.png';
import axios from 'axios';
import { ReactTagManager } from 'react-gtm-ts';
import { useTranslation } from 'react-i18next';

const tagManagerArgs = {
    code: 'GTM-WZQPMC7',
};

const wixAppId = '3e1b36e5-8c69-4622-b01d-e7ec82ff301b';

const CONTENT = [
    'Access all components',
    'Access Pro plan on all sites you own',
    'To get priority support',
];

const INTERVAL = 4000;

interface Dashboard {
    instanceId: string;
    instance: {
        site: {
            siteDisplayName: string;
            ownerInfo: {
                email: string;
            };
        };
        isFree: boolean;
    };
    intercomHash: string;
}

function App() {
    const { t } = useTranslation();
    ReactTagManager.init(tagManagerArgs);

    const token = new URLSearchParams(window.location.search).get('token');
    const instance = new URLSearchParams(window.location.search).get('instance');

    const [dashboard, setDashboard] = React.useState<Dashboard | null>(null);
    const [isLoaded, setLoaded] = React.useState(false);
    const [appId, setAppId] = React.useState('');
    const [linkResult, setLinkResult] = React.useState(null as any);

    const [isAddSiteModalOpened, setAddSiteModalOpened] = React.useState(false);

    const [isVideoModalOpened, setVideoModalOpened] = React.useState(false);

    const openVideoModal = () => {
        setVideoModalOpened(true);
    };
    const closeVideoModal = () => setVideoModalOpened(false);

    const [isUpgradeModalOpened, setUpgradeModalOpened] = React.useState(false);

    // const isUpgraded = !(dashboard ? dashboard?.['instance']['instance']['isFree'] : true);
    const isUpgraded = !(dashboard ? dashboard.instance.isFree : true);
    const [index, setIndex] = React.useState<number>(0);
    const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] = React.useState<boolean>(isUpgraded);

    React.useEffect(() => {
        const sliderInterval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
        }, INTERVAL);

        return () => clearInterval(sliderInterval);
    }, [CONTENT, INTERVAL]);

    const openUpgradeModal = () => {
        setUpgradeModalOpened(true);
    };
    const closeUpgradeModal = () => setUpgradeModalOpened(false);

    const renderVideoModalContent = () => (
        <Box height="80%" width="80%">
            <iframe
                title={t('demo-title')}
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/bIVsRPmc5bE?autoplay=1`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </Box>
    );

    React.useEffect(() => {
        axios
            .get('https://certifiedcode.editorx.io/library/_functions/dashboard', {
                params: {
                    instance: instance,
                },
            })
            .then((response) => {
                setDashboard(response.data);
                setIsUpgradeBannerOpen(
                    !(response?.data?.['instance']['instance']['isFree'] ?? true)
                );
                setLoaded(true);
                window.Intercom('boot', {
                    email: response.data?.['instance']['site']['ownerInfo']['email'],
                    user_hash: response.data?.['intercomHash'],
                });
            })
            .catch((error) => {
                setLoaded(true);
                console.error(error);
                throw error;
            });
    }, []);

    if (token) {
        window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=${wixAppId}&redirectUrl=https://certifiedcode.editorx.io/library/_functions/@certifiedcode/base-backend/auth`;
    }

    if (!dashboard && isLoaded && instance) {
        return (
            <WixDesignSystemProvider features={{ newColorsBranding: true }}>
                <Page sidePadding={0}>
                    <Page.Content>
                        <Box
                            padding="10px"
                            align="center"
                            verticalAlign="middle"
                            height={'90vh'}
                            direction="vertical"
                        >
                            <Cell>
                                <CircularProgressBar error value={100} />
                            </Cell>
                            <EmptyState
                                theme={'page-no-border'}
                                title={t('error-msg-title')}
                                subtitle={t('error-msg-subtitle')}
                            />
                        </Box>
                    </Page.Content>
                </Page>
            </WixDesignSystemProvider>
        );
    }

    if (!dashboard && instance) {
        return (
            <WixDesignSystemProvider features={{ newColorsBranding: true }}>
                <Page>
                    <Page.Content>
                        <Box
                            padding="10px"
                            align="center"
                            verticalAlign="middle"
                            height={'90vh'}
                            direction="vertical"
                        >
                            <Loader text={t('loading')} />
                        </Box>
                    </Page.Content>
                </Page>
            </WixDesignSystemProvider>
        );
    }

    const instanceId = dashboard?.['instanceId'];
    const renderUpgradeModalContent = () => (
        <AnnouncementModalLayout
            theme="premium"
            illustration={'https://www.wix-style-react.com/storybook/generic_post.svg'}
            title="Access to 500+ components today"
            primaryButtonText={t('upgrade-button')}
            // linkText="or upgrade to our limited lifetime offer for $99"
            onCloseButtonClick={closeUpgradeModal}
            primaryButtonOnClick={() => {
                window.open(
                    `https://www.wix.com/apps/upgrade/${wixAppId}?appInstanceId=${instanceId}`,
                    '_blank'
                );
            }}
            // linkOnClick={() => { window.location.href = `https://www.wix.com/apps/upgrade/${wixAppId}?appInstanceId=${instanceId}` }}
        >
            <Text>Upgrade your Library premium plan to access all components today.</Text>
        </AnnouncementModalLayout>
    );

    const renderModalContent = () => {
        function createShare() {
            axios
                .get(`https://certifiedcode.editorx.io/library/_functions/link`, {
                    params: {
                        instance: instance,
                        appId: appId,
                    },
                })
                .then((response) => {
                    setLinkResult(response.data);
                })
                .catch((error) => {
                    setLinkResult({ success: false, reason: 'Unknown error' });
                });
        }
       
        return (
            <CustomModalLayout
                primaryButtonText={isUpgraded ? t('confirm-button') : t('upgrade-button')}
                secondaryButtonText="Cancel"
                primaryButtonOnClick={() => {
                    if (appId !== '' && isUpgraded) {
                        createShare();
                        setAddSiteModalOpened(false);
                    } else {
                        if (!isUpgraded) {
                            openUpgradeModal();
                        }
                    }
                }}
                secondaryButtonOnClick={() => setAddSiteModalOpened(false)}
                onCloseButtonClick={() => setAddSiteModalOpened(false)}
                title={t('share-title')}
                subtitle={t('share-subtitle')}
                footnote={
                    <CopyClipboard value={instanceId || ''} resetTimeout={1500}>
                    {({ isCopied, copyToClipboard }: { isCopied: boolean; copyToClipboard: () => void }) => (
                        <FormField
                            label={t('app-id-label', {
                                siteName: dashboard?.instance?.site?.siteDisplayName || 'Unknown',
                            })}
                        >
                            <Input
                                readOnly
                                value={instanceId}
                                suffix={
                                    <Box verticalAlign="middle" marginRight="SP1">
                                        <TextButton
                                            onClick={() => copyToClipboard()}
                                            size="small"
                                            prefixIcon={<Icons.DuplicateSmall />}
                                        >
                                            {!isCopied ? t('copy') : t('copied')}
                                        </TextButton>
                                    </Box>
                                }
                            />
                        </FormField>
                    )}
                </CopyClipboard>
                
                
                }
                // sideActions={

                // }
                content={
                    <Layout>
                        <Cell>
                            <FloatingNotification
                                text={
                                    t('site-owner-text') +
                                    (dashboard as any)?.instance?.site?.ownerInfo?.email
                                }
                                showCloseButton={false}
                            ></FloatingNotification>
                        </Cell>
                        {isUpgraded ? (
                            <Cell>
                                <FormField label="App ID">
                                    <Input
                                        value={appId}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAppId(e.target.value)}
                                    />
                                </FormField>
                            </Cell>
                        ) : (
                            <Cell>
                                <FloatingNotification
                                    type="premium"
                                    text={t('share-upgrade-text')}
                                    showCloseButton={false}
                                ></FloatingNotification>
                            </Cell>
                        )}
                        <Cell></Cell>
                    </Layout>
                }
            />
        );
    };

    return (
        <WixDesignSystemProvider features={{ newColorsBranding: true }}>
            <Notification theme="premium" show={isUpgradeBannerOpen} type={'sticky'}>
                <Notification.TextLabel>
                {t('upgradeMessage', { content: t(`content.${index}`) })}
                </Notification.TextLabel>
                <Notification.ActionButton
                    onClick={() => {
                        window.open(
                            `https://www.wix.com/apps/upgrade/${wixAppId}?appInstanceId=${instanceId}`,
                            '_blank'
                        );
                    }}
                >
                    {t('upgrade-button')}
                </Notification.ActionButton>
                <Notification.CloseButton onClick={() => setIsUpgradeBannerOpen(false)} />
            </Notification>
            <Notification
                theme={(linkResult as any)?.success ? 'standard' : 'error'}
                show={!!linkResult}
            >
                <Notification.TextLabel>
                    {(linkResult as any)?.success
                        ? t('success-msg')
                        : (linkResult as any)?.reason}
                </Notification.TextLabel>
                <Notification.CloseButton />
            </Notification>
            <Modal
                isOpen={isAddSiteModalOpened}
                onRequestClose={() => setAddSiteModalOpened(false)}
                shouldCloseOnOverlayClick={true}
            >
                {renderModalContent()}
            </Modal>
            <Card stretchVertically={true}>
                <MarketingPageLayout
                    content={
                        <Box verticalAlign="middle">
                            <MarketingPageLayoutContent
                                title={t('premium-title')}
                                subtitle={t('premium-subtitle')}
                                content={
                                    <Text>
                                        <ul>
                                            <li>{t('premium-text-1')}</li>
                                            <li>{t('premium-text-2')}</li>
                                            <li>{t('premium-text-3')}</li>
                                        </ul>
                                    </Text>
                                }
                                actions={
                                    <Box gap="12px">
                                        <Button
                                            as="a"
                                            href={
                                                'https://chrome.google.com/webstore/detail/library-ui-components-for/bidnnalgdambofjfpcdncpjfmamhelmi'
                                            }
                                            target="_blank"
                                            size="medium"
                                        >
                                            {t('chrome-extension-msg')}
                                        </Button>
                                        <Button
                                            priority="secondary"
                                            prefixIcon={<Icons.PlayFilled />}
                                            onClick={() => openVideoModal()}
                                        >
                                            {t('library-msg')}
                                        </Button>
                                        <Modal
                                            screen={'desktop'}
                                            isOpen={isVideoModalOpened}
                                            onRequestClose={closeVideoModal}
                                            shouldDisplayCloseButton={true}
                                            shouldCloseOnOverlayClick={true}
                                        >
                                            {renderVideoModalContent()}
                                        </Modal>
                                        <Modal
                                            screen={'desktop'}
                                            isOpen={isUpgradeModalOpened}
                                            onRequestClose={closeUpgradeModal}
                                            shouldCloseOnOverlayClick={true}
                                        >
                                            {renderUpgradeModalContent()}
                                        </Modal>
                                    </Box>
                                }
                            />
                        </Box>
                    }
                    image={
                        <Image height="450px" fit="contain" transparent={true} src={LandingImage} />
                    }
                    footer={
                        <Layout>
                            <Cell span={6}>
                                <MarketingLayout
                                    title={t('business-title')}
                                    description={t('business-content')}
                                    actions={
                                        <Button
                                            size="medium"
                                            suffixIcon={<Icons.ExternalLink />}
                                            as="a"
                                            href="https://www.certifiedcode.us/templates"
                                            target="_blank"
                                        >
                                            {t('template-button')}
                                        </Button>
                                    }
                                    size="tiny"
                                />
                            </Cell>
                            <Cell span={6}>
                                <MarketingLayout
                                    title={t('acess-title')}
                                    description={t('access-content')}
                                    actions={
                                        <Button
                                            size="medium"
                                            onClick={() => openUpgradeModal()}
                                            skin="premium"
                                            prefixIcon={<Icons.PremiumFilled />}
                                            disabled={dashboard?.['instance'] ? isUpgraded : true}
                                        >
                                            {dashboard?.['instance']
                                                ? isUpgraded
                                                    ? t('upgraded-msg')
                                                    : t('learn-more-button')
                                                : t('not-available-button')}
                                        </Button>
                                    }
                                    size="tiny"
                                />
                            </Cell>
                            <Cell span={6}>
                                <MarketingLayout
                                    title={
                                        <Box direction="horizontal" gap="SP2">
                                            <Heading size="medium" as="h2">
                                              {t('more-website-title')}
                                            </Heading>
                                            <Badge size="tiny">Beta</Badge>
                                        </Box>
                                    }
                                    description={
                                        isUpgraded
                                            ? t('more-share-msg')
                                            : t('more-upgrade-msg')
                                    }
                                    actions={
                                        <Button
                                            size="medium"
                                            skin="standard"
                                            prefixIcon={<Icons.Add />}
                                            onClick={() => {
                                                setAddSiteModalOpened(true);
                                            }}
                                        >
                                            {t('add-to-Site-button')}
                                        </Button>
                                    }
                                    size="tiny"
                                />
                            </Cell>
                            <Cell span={6}>
                                <MarketingLayout
                                    title={t('love-app-title')}
                                    description={t('love-app-content')}
                                    actions={
                                        <Button
                                            prefixIcon={<Icons.Reviews />}
                                            skin="inverted"
                                            as="a"
                                            href="https://bridget.reviews.certifiedcode.us/3e1b36e5-8c69-4622-b01d-e7ec82ff301b"
                                            target="_blank"
                                        >
                                            {t('review-button')}
                                        </Button>
                                    }
                                    size="tiny"
                                />
                            </Cell>
                        </Layout>
                    }
                />
            </Card>
        </WixDesignSystemProvider>
    );
}

export default App;
